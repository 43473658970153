<template>
    <div class="flex flex-col h-full">
        <LayoutChatNav @reload-chat="reloadChat" @open-directeur-chat="openDirecteurChat" />
        <iframe ref="chatIframe" v-if="chatUser" id="chat-iframe" class="h-[calc(100vh-53px)] max-w-full"
            :class="{ 'pointer-events-none': resizing }" src="https://chat.libechat.ca" width="100%" />

        <div class="p-4" v-else>
            <h3>
                Configurer votre compte
            </h3>
            <p>
                Afin de pouvoir accéder à la messagerie de Libe, vous devez créer un mot de passe sécurisé.
            </p>
            <Message class="mt-4" severity="warn" icon="pi pi-exclamation-triangle">
                Souvenez-vous bien de votre mot de passe initial ainsi que de votre nom d'utilisateur, car vous devrez l'entrer par la suite.
            </Message>
            <form @submit.prevent="createChatUser" class="flex flex-col gap-4 mt-4">
                <IftaLabel class="w-full">
                    <InputText fluid id="username" v-keyfilter="/^[a-z0-9._]{1,32}$/" size="large" v-model="username" />
                    <label for="username">Nom d'utilisateur</label>
                </IftaLabel>
                <IftaLabel class="w-full">
                    <Password required toggle-mask fluid id="password" size="large" type="password" v-model="password"
                        promptLabel="Entrez un mot de passe" weakLabel="Faible" mediumLabel="Moyen"
                        strongLabel="Fort" />
                    <label for="password">Mot de passe initial (vous pourrez le changer plus tard)</label>
                </IftaLabel>
                <Message severity="info" icon="pi pi-lock">
                    Le mot de passe initial ne sera pas stocké sur nos serveurs. Il sera seulement utilisé pour vous
                    identifier à la messagerie de Libe.
                </Message>
                <Button size="large" type="submit" label="Créer mon compte" :disabled="pending" />
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
const toast = useToast();
const { resizing, targetRoomId } = defineProps<{
    resizing: boolean;
    targetRoomId: string | null;
}>();

const chatIframe = ref<HTMLIFrameElement | null>(null);
const emits = defineEmits(['reload-chat']);
const route = useRoute();
const chatOpened = useChatOpened();

const reloadChat = () => {
    emits('reload-chat');
};

const navigateToRoom = (roomId: string) => {
    if (chatIframe.value && roomId) {
        chatIframe.value.src = `https://chat.libechat.ca/#/room/${roomId}`;
    }
};

// Navigate to target room when iframe is mounted
watch(chatIframe, (iframe) => {
    if (iframe && targetRoomId) {
        // Small delay to ensure iframe is ready
        setTimeout(() => {
            navigateToRoom(targetRoomId);
        }, 500);
    }
});

// Watch for goToRoom parameter changes
watch(
    () => route.query.goToRoom,
    (newRoomId) => {
        if (newRoomId && typeof newRoomId === 'string') {
            // Open the chat if it's not already opened
            if (!chatOpened.value) {
                chatOpened.value = true;
            }
            // Wait a bit for the iframe to be mounted
            setTimeout(() => {
                navigateToRoom(newRoomId);
            }, 500);
        }
    },
    { immediate: true }
);

const { data: chatUser, pending, refresh } = useFetch('/api/chat/user');
const currentUser = useCurrentUser();

interface ChatUser {
    id: number;
    userId: number;
    createdAt: Date | null;
    updatedAt: Date | null;
    username: string;
    userRoomId: string;
}

interface ChatUserResponse {
    success: boolean;
    message: string;
}

type ChatUserData = ChatUser | ChatUserResponse;

const username = ref(sanitizeUsername(extractLocalPart(currentUser.value?.email || '')));
const password = ref("");

function extractLocalPart(email: string): string {
    return email.split('@')[0];
}

function sanitizeUsername(localPart: string): string {
    // Convert to lowercase
    let username = localPart.toLowerCase();
    // Remove invalid characters
    username = username.replace(/[^a-z0-9._-]/g, '');
    return username;
}

const createChatUser = async () => {
    try {
        pending.value = true;
        await $fetch('/api/chat/user', {
            method: 'POST',
            body: { username: username.value, password: password.value }
        });
        toast.add({
            severity: 'success',
            summary: 'Succès',
            detail: 'Votre compte a été créé avec succès',
            life: 3000
        });
        refresh();
    } catch (err: unknown) {
        console.error('Error creating chat user:', err);
        toast.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Une erreur est survenue lors de la création de votre compte',
            life: 3000
        });
    } finally {
        pending.value = false;
    }
}

const openDirecteurChat = () => {
    const user = chatUser.value as ChatUser;
    if (chatIframe.value && user?.userRoomId) {
        navigateToRoom(user.userRoomId);
    }
};
</script>