<template>
  <div>
    <Splitter state-storage="local" state-key="chat-splitter-state" style="height: 100vh;"
      @resizestart="onResizeStart" @resizeend="onResizeEnd">
      <SplitterPanel>
        <div class="flex relative lg:static">
          <LayoutSidebar :class="{ 'hidden lg:block': !sidebarOpened }" :items="items" :compacted="compacted"
            @toggle-sidebar-opened="toggleSidebarOpened" />
          <LayoutContentArea :nav-bar-items="navBarItems" :compacted="compacted"
            @toggle-compact="compacted = !compacted" @toggle-sidebar-opened="toggleSidebarOpened">
            <slot />
          </LayoutContentArea>
        </div>
      </SplitterPanel>
      <SplitterPanel v-if="chatOpened">
        <div class="h-screen w-full">
          <ChatContainer :resizing="resizing" :target-room-id="targetRoomId" @reload-chat="reloadChat" />
        </div>
      </SplitterPanel>
    </Splitter>

  </div>
</template>

<script setup lang="ts">
const compacted = ref<boolean>(false);
const sidebarOpened = ref(false);
const navBarItems = ref([]);
const chatOpened = useChatOpened();
const resizing = ref(false);
const route = useRoute();
const targetRoomId = ref<string | null>(null);

// Watch for goToRoom parameter changes
watch(
    () => route.query.goToRoom,
    (newRoomId) => {
        if (newRoomId && typeof newRoomId === 'string') {
            targetRoomId.value = newRoomId;
            chatOpened.value = true;
        }
    },
    { immediate: true }
);

const onResizeStart = () => {
  resizing.value = true;
};

const onResizeEnd = () => {
  resizing.value = false;
};

const { items } = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

onMounted(() => {
  const savedCompactedState = localStorage.getItem("sidebarCompacted");
  if (savedCompactedState !== null) {
    compacted.value = JSON.parse(savedCompactedState);
  }
});

watch(compacted, (isCompacted: boolean) => {
  localStorage.setItem("sidebarCompacted", JSON.stringify(isCompacted));
});

const toggleSidebarOpened = (value: boolean | undefined) => {
  if (value !== undefined) {
    sidebarOpened.value = value;
  } else {
    sidebarOpened.value = !sidebarOpened.value;
  }
};

const toggleCompact = () => {
  compacted.value = !compacted.value;
};

const reloadChat = () => {
  const iframe = document.getElementById('chat-iframe') as HTMLIFrameElement;
  if (iframe) {
    iframe.src = iframe.src;
  }
};
</script>
